<template>
  <div>
    Not Found
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
    mounted () {
      this.$router.push({ name: 'Home'}) 
    }
  }
</script>
